import { useEffect, useLayoutEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import Image from "next/image";
import {
  Method,
  PaymentMethod,
} from "@olga-food/schemas/lib/classes/schemas/order/payment/Payment";
import dayjs from "dayjs";

import styles from "@/styles/containers/cart-receive-on.module.scss";

import {
  Blocked,
  CheckCircle2,
  ChevronRight,
  CreditCardB,
  Gift,
  GiftLight,
  PenCircle,
  UserLight,
} from "@/shared/components/Icons";
import { Button, Drawer, Input, Modal } from "@/shared/components";

import CartItem from "@/shared/containers/Cart/CartItem";

import { OrderStorage } from "@/storage/OrderStorage";
import { UnitStorage } from "@/storage/UnitStorage";
import { BenefitStorage } from "@/storage/BenefitStorage";
import { PaymentStorage } from "@/storage/PaymentStorage";

import { Holding } from "@/shared/core/Holding";
import { Brand, BrandEvents } from "@/shared/core/Brand";
import { Store, StoreEvents } from "@/shared/core/Store";
import { Customer, CustomerEvents } from "@/shared/core/Customer";
import { Catalog } from "@/shared/core/Catalog";
import { Table } from "@/shared/core/Table";
import { Payment, PaymentEvents, PaymentOrigin } from "@/shared/core/Payment";
import { Flows } from "@/shared/core/Enum";

import { Benefit } from "@/shared/tools/Benefit";
import { CatalogHelpers, Currency } from "@/shared/tools";
import { DataLayer } from "@/shared/tools/DataLayer";

import useEventListener from "@/shared/hooks/useEventListener";

import { OrderAPI } from "api/OrderAPI";
import { BucketsAPI } from "api/BucketAPI";
import { OpenTableAPI } from "@/api/OpenTableAPI";

import PaymentMethods from "../Payment/PaymentMethods";
import ProductDetails from "../Product/ProductDetails";
import Benefits from "../Benefits/Benefits";

import classNames from "classnames/bind";
import { CartTotals } from "./CartTotals";
import { CartItemsResume } from "./CartItemsResume";
import { CartBenefit } from "./CartBenefit";
import { CartDelivery } from "./CartDelivery";
import { CardTypes } from "../Payment/Card";
import { PaymentSection } from "./PaymentSection";
import { PixPayment } from "./PixPayment";
import CheckoutReceiveOn from "./CheckoutReceiveOn";

export default function CartReceiveOn({ open, onClose, onOpenTable }) {
  const [isCheckout, setIsCheckout] = useState(false);
  const [order, setOrder] = useState(OrderStorage.getOrder());
  const [customer, setCustomer] = useState(Customer.getCustomer());
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cartItemIndex, setCartItemIndex] = useState(null);
  const [openBenefits, setOpenBenefits] = useState(false);
  const [openConfirmOrder, setOpenConfirmOrder] = useState(false);
  const [hasClubAccess, setHasClubAccess] = useState(false);
  const [cardSelected, setCardSelected] = useState(null);
  const [store, setStore] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [openPix, setOpenPix] = useState(false);
  const [pixOrderId, setPixOrderId] = useState(null);
  const [sendingOrder, setSendingOrder] = useState(false);
  const [sentOrder, setSentOrder] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);

  const [isHoldingDomain, setIsHoldingDomain] = useState(false);

  useLayoutEffect(() => {
    setIsHoldingDomain(Holding.alias && !Brand.alias);
  }, []);

  useEventListener(BrandEvents.BRAND_CHANGED, () => {
    setIsHoldingDomain(Holding.alias && !Brand.alias);
  });

  const cartItemsCount = order?.cart?.catalog?.items.reduce(
    (t, i) => t + (i.quantity || 1),
    0
  );

  const router = useRouter();

  const onEditProduct = (product, cartItemIndex) => {
    let productToUpdate = null;
    if (product.type === "pizza") {
      productToUpdate = Catalog.preparePizzaProductToUpdate(product);
    } else {
      productToUpdate = product;
    }

    setSelectedProduct(JSON.parse(JSON.stringify(productToUpdate)));
    setCartItemIndex(cartItemIndex);
    setOpenProductDetails(true);
  };

  const onOrderChanged = async () => {
    const order = OrderStorage.getOrder();
    setOrder(order);

    if (Payment.currentOrigin == PaymentOrigin.OFFLINE) {
      const isMoney =
        Payment.offlinePayment.type &
        (PaymentMethod.MONEY == PaymentMethod.MONEY);
      const change_for = +Payment.offlinePayment.change_for;
      if (isMoney && change_for < order.total) {
        Payment.offlinePayment = {};
      }
    }

    if (selectedBenefit && !Benefit.canBeUsed(selectedBenefit)) {
      BenefitStorage.setSelectedBenefit(null);
    }
  };

  const onCustomerChanged = () => {
    setCustomer(Customer.getCustomer());
    setHasClubAccess(!!Customer.phone);
  };

  useEventListener(CustomerEvents.CUSTOMER_CHANGED, onCustomerChanged);

  const onStoreChanged = () => {
    const store = Store.getStore();
    setStore(store);
    setShowPayment(store ? true : false);
  };

  useEventListener(StoreEvents.STORE_CHANGED, onStoreChanged);
  useEventListener(PaymentEvents.PAYMENT_CHANGED, () => onCardChanged());

  const onBenefitChanged = ({ detail: coupon }) => {
    setSelectedBenefit(coupon);

    if (!coupon?.type) {
      OrderStorage.setGift(null);
      return;
    }

    const getCode = (coupon) => {
      if (coupon.code) {
        return `${coupon.code}-${coupon.pin}`;
      } else {
        return null;
      }
    };

    OrderStorage.setGift({
      id: coupon.coupon,
      code: getCode(coupon),
      type: Benefit.parseCouponType(coupon),
      value: coupon.value || 0,
      ...(coupon.type == "CASHBACK_CONSUME" ? { amount: coupon.amount } : {}),
      min_order: coupon.min_order_amount,
      expiration: null,
      created_at: coupon.created_at,
    });
  };

  const onCardChanged = () => {
    const selectedCard = Payment.selectedCard;
    if (selectedCard) {
      const cardSelected = PaymentStorage.getCard().filter(
        (item) => item.id == selectedCard
      );
      setCardSelected(cardSelected[0]);
    }
  };

  useEffect(() => {
    setCustomer(Customer.getCustomer());

    const selectedBenefit = BenefitStorage.getSelectedBenefit();
    setSelectedBenefit(selectedBenefit);
    onBenefitChanged({ detail: selectedBenefit || {} });

    window.addEventListener("orderUpdated", onOrderChanged);

    onCardChanged();
    BenefitStorage.attachOnBenefitChanged(onBenefitChanged);

    onStoreChanged();

    return () => {
      window.removeEventListener("orderUpdated", onOrderChanged);
      BenefitStorage.detachOnBenefitChanged(onBenefitChanged);
    };
  }, []);

  useEffect(() => {
    const selectedBenefit = BenefitStorage.getSelectedBenefit();

    if (selectedBenefit && !Benefit.canBeUsed(selectedBenefit)) {
      BenefitStorage.setSelectedBenefit(null);
    }

    setSelectedBenefit(selectedBenefit);
  }, [order]);

  const renderCartItems = () => {
    return (
      <>
        <CartItem
          order={order}
          onEditProduct={onEditProduct}
          onRemoveProduct={(_, productIndex) => {
            OrderStorage.removerCartItem(productIndex);
          }}
          onClose={() => onClose && onClose({ openCatalog: true })}
        />

        <div className={styles.totals}>
          <span>Total</span>
          <p>{Currency.formatCurrency(order?.total)}</p>
        </div>
      </>
    );
  };

  const onSendOrder = async () => {
    setSendingOrder(true);

    const { cart } = order;
    const cartItems = cart?.catalog?.items;
    if (cartItems?.length <= 0) {
      setError("Carrinho vazio");
      setSendingOrder(false);
      setShowError(true);
    }

    try {
      const addItemsReq = await OpenTableAPI.addTableItems(Store.id, Table.id, order);
      const addItemsErrors = addItemsReq.getErrors([]);

      if (addItemsErrors?.length > 0) {
        throw new Error(addItemsErrors[0]);
      }

      setError(null);
      setShowError(false);

      OrderStorage.clearCartItems();
      setSentOrder(true);
    } catch (err) {
      setError(err.message || "Erro de comunicação ao solicitar itens.");
      setSentOrder(false);
      setShowError(true);
    }

    setSendingOrder(false);
  };

  if (!order) {
    return null;
  }

  const orderFlow = OrderStorage.flow;

  return (
    <Drawer
      open={open}
      onClose={() => !sendingOrder && onClose()}
      title={`Seu pedido (${cartItemsCount})`}
      showBackButton
      withoutPadding
      backgroundColor="#F0F0F0"
      direction={isMobile ? "top-full" : "right"}
    >
      <div className={styles.cart}>
        <div className={styles.scrollContainer}>
          {order?.cart?.catalog?.items.length > 0 && renderCartItems()}
        </div>

        <div className={styles.footer}>
          {orderFlow === Flows.OPEN_TABLE && (
            <Button
              design="primary"
              onClick={onSendOrder}
              disabled={cartItemsCount === 0}
            >
              Fazer pedido
            </Button>
          )}

          {(orderFlow === Flows.ORDER_AHEAD ||
            orderFlow === Flows.TAKE_AWAY) && (
            <Button
              design="primary"
              onClick={() => setOpenCheckout(true)}
              disabled={cartItemsCount === 0}
            >
              Fazer pedido
            </Button>
          )}
        </div>
      </div>

      <CheckoutReceiveOn
        open={openCheckout}
        onClose={() => setOpenCheckout(false)}
      />

      <Drawer
        open={sendingOrder}
        withoutPadding
        withoutTransition
        alreadyDrawer
      >
        <div className={styles.sendingOrder}>
          <div className={styles.brandHeader}>
            {Brand.avatar && (
              <div className={styles.picture}>
                <Image src={Brand.avatar} alt={Brand.name} layout="fill" />
              </div>
            )}
          </div>

          {!sentOrder && (
            <>
              <div className={styles.statusContainer}>
                <p className={styles.msgOne}>
                  Estamos recebendo o seu pedido...
                </p>
                <p className={styles.msgTwo}>
                  Aguarde um pouco, estamos quase lá!
                </p>
              </div>

              <span className={styles.loader} />
            </>
          )}

          {sentOrder && (
            <div className={styles.statusContainer}>
              <p>Pedido enviado!</p>
            </div>
          )}
        </div>
      </Drawer>

      <Drawer open={sentOrder} alreadyDrawer direction="bottom-center">
        <div className="d-flex justify-center mb-20">
          <CheckCircle2 />
        </div>
        <p className={styles.alertTitle}>Sucesso!</p>
        <p className={styles.alertSubtitle}>
          Seu pedido foi enviado para a cozinha!
        </p>
        <Button
          design="primary"
          className="w-100 mb-10"
          onClick={() => {
            setSentOrder(false);
            setSendingOrder(false);
            onClose({ openCatalog: !isMobile });
          }}
        >
          Voltar ao cardápio
        </Button>
        <Button
          design="secondary"
          className="w-100"
          onClick={() => {
            isMobile
              ? isHoldingDomain
                ? router.push(`/mesa`)
                : router.push(`/${Brand.alias}/mesa`)
              : null;

            isDesktop ? onOpenTable() : null;

            setSentOrder(false);
            setSendingOrder(false);
            onClose({ openCatalog: !isMobile });
          }}
        >
          Ver minha mesa
        </Button>
      </Drawer>

      <Drawer open={showError} alreadyDrawer direction="bottom-center">
        <p className={styles.alertTitle}>Tente novamente!</p>
        <p className={styles.alertSubtitle}>{error}</p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => {
            setShowError(false);
            setError("");
          }}
        >
          Ok
        </Button>
      </Drawer>

      <Drawer
        open={openProductDetails}
        onClose={() => setOpenProductDetails(false)}
        withoutPadding
        alreadyDrawer
      >
        <ProductDetails
          cartItemIndex={cartItemIndex}
          product={selectedProduct}
          onClose={() => setOpenProductDetails(false)}
        />
      </Drawer>

      {pixOrderId && (
        <PixPayment
          open={openPix}
          onClose={() => setOpenPix(false)}
          onSuccess={(orderId) => {
            setOpenPix(false);
            successOrder(orderId);
          }}
          orderId={pixOrderId}
        />
      )}
    </Drawer>
  );
}
